import React from 'react'
import Layout from '../components/layout'
import Meta from '../components/meta'
import { Link, graphql } from 'gatsby'
import filterLocale from '../components/utils/filter-locale'
import Hero from '../components/testimonials/hero'
import TestimonialItems from '../components/testimonials/testimonial-items'
import EndBanner from '../components/testimonials/end-banner'

const Testimonials = ({ data : {
  testimonial,
  Lg,
  Md,
  Sm,
  Xs,
  testimonies,
  endbanner
}}) => {
  const locale = 'en-NZ'
  const [{ node }] = filterLocale(testimonial.edges, locale)
  const [{ node:lg }] = filterLocale(Lg.edges, locale)
  const [{ node:md }] = filterLocale(Md.edges, locale)
  const [{ node:sm }] = filterLocale(Sm.edges, locale)
  const [{ node:xs }] = filterLocale(Xs.edges, locale)
  const [{ node: endBanner }] = filterLocale(endbanner.edges, locale)

  const testimonyByLocale = (item, locale) => {
    return item.edges.filter(i => i.node.node_locale === locale)
  }

  const testimony = testimonyByLocale(testimonies, locale)

  const {
    seoTitle,
    seoKeywords,
    seoDescription,
    node_locale,
    backgroundImage
  } = node
  const bannerSources = [
    {
      ...backgroundImage.fluid,
      srcSet: `${backgroundImage.fluid.src} 1920w`,
      srcSetWebp: `${backgroundImage.fluid.srcWebp} 1920w`,
      media: `(min-width: 1350px)`,
    },
    {
      ...lg.backgroundImage.fluid,
      srcSet: `${lg.backgroundImage.fluid.src} 1349w`,
      srcSetWebp: `${lg.backgroundImage.fluid.srcWebp} 1349w`,
      media: `(min-width: 1280px) and (max-width: 1349px)`,
    },
    {
      ...md.backgroundImage.fluid,
      srcSet: `${md.backgroundImage.fluid.src} 1279w`,
      srcSetWebp: `${md.backgroundImage.fluid.srcWebp} 1279w`,
      media: `(min-width: 1024px) and (max-width: 1279px)`,
    },
    {
      ...sm.backgroundImage.fluid,
      srcSet: `${sm.backgroundImage.fluid.src} 1023w`,
      srcSetWebp: `${sm.backgroundImage.fluid.srcWebp} 1023w`,
      media: `(min-width: 768px) and (max-width: 1023px)`,
    },
    {
      ...xs.backgroundImage.fluid,
      srcSet: `${xs.backgroundImage.fluid.src} 767w`,
      srcSetWebp: `${xs.backgroundImage.fluid.srcWebp} 767w`,
      media: `(max-width: 767px)`,
    },
  ]

  return (
    <Layout>
      <Meta
        title={seoTitle}
        keywords={seoKeywords}
        description={seoDescription}
      />
      <Hero {...{
        seoTitle,
        bannerSources
      }} />
      <TestimonialItems testimony={testimony}/>
      {/* <EndBanner data={endBanner}/> */}
    </Layout>
  )
}

export const TestimonialQuery = graphql`
  query TestmimonialQueryNz {
    testimonial:allContentfulPage(filter: {contentful_id: {eq: "6m8e3JPKlQXowQBXl47FRW"}}) {
      edges {
        node {
          seoTitle
          slug
          node_locale
          seoKeywords
          seoDescription
          backgroundImage {
            fluid(
							maxWidth: 1920
							maxHeight: 800
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
              src
              srcWebp
            }
          }
        }
      }
    }
    Lg:allContentfulPage(filter: {contentful_id: {eq: "6m8e3JPKlQXowQBXl47FRW"}}) {
      edges {
        node {
          node_locale
          backgroundImage {
            fluid(
							maxWidth: 1349
							maxHeight: 750
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
              src
              srcWebp
            }
          }
        }
      }
    }
    Md:allContentfulPage(filter: {contentful_id: {eq: "6m8e3JPKlQXowQBXl47FRW"}}) {
      edges {
        node {
          node_locale
          backgroundImage {
						fluid(
							maxWidth: 1279
							maxHeight: 1000
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
              src
              srcWebp
            }
          }
        }
      }
    }
    Sm:allContentfulPage(filter: {contentful_id: {eq: "6m8e3JPKlQXowQBXl47FRW"}}) {
      edges {
        node {
          node_locale
          backgroundImage {
						fluid(
							maxWidth: 1023
							maxHeight: 600
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
              src
              srcWebp
            }
          }
        }
      }
    }
    Xs:allContentfulPage(filter: {contentful_id: {eq: "6m8e3JPKlQXowQBXl47FRW"}}) {
      edges {
        node {
          node_locale
          backgroundImage {
						fluid(
							maxWidth: 768
							maxHeight: 500
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
              src
              srcWebp
            }
          }
        }
      }
    }
    testimonies:allContentfulTestimonials {
      edges {
        node {
          authorDescription
          author
          node_locale
          body {
            json
          }
        }
      }
    }
    endbanner:allContentfulBanner(filter: {contentful_id: {eq: "5760vgTXYVEQRTSuFCZy3g"}}) {
      edges {
        node {
          hook
          mainActionText
          mainActionUrl
          node_locale
          backgroundImage {
            fluid(
							maxWidth: 1920
							maxHeight: 525
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
              src
              srcWebp
            }
          }
        }
      }
    }
  }
`


export default Testimonials
